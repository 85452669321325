import { createClient } from "react-fetching-library";

import { requestHostInterceptor } from "./interceptors/requestHostInterceptor";
import { responseInterceptor } from "./interceptors/responseInterceptor";

// In real application this const will be stored in ENV's
const HOST = "/api";

export const Client = createClient({
  requestInterceptors: [requestHostInterceptor(HOST)],
  responseInterceptors: [responseInterceptor],
});
