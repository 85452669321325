import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from "antd";
import "./ValidationDrawer.css";

interface DrawerProps extends AntdDrawerProps {
  visible: boolean;
  onClose: any;
  children: React.ReactNode;
}
const ValidationDrawer = ({
  onClose,
  visible,
  children,
  ...args
}: DrawerProps) => {
  return (
    <>
      <AntdDrawer width={532} onClose={onClose} visible={visible} {...args}>
        {children}
      </AntdDrawer>
    </>
  );
};

export default ValidationDrawer;
