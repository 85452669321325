import { ReactElement } from "react";
import { Routes, Route } from "react-router-dom";
import {
  ListGroupings,
  PreviewDataType,
  CreateDataType,
  EditDataType,
  NotFound,
} from "../pages";

export const routes = (): ReactElement => (
  <Routes>
    <Route path="/" element={<ListGroupings />} />
    <Route path="/datatype/preview/:dataTypeId" element={<PreviewDataType />} />
    <Route path="/datatype/create/:groupingId" element={<CreateDataType />} />
    <Route path="/datatype/edit/:dataTypeId" element={<EditDataType />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
