const getValue = (name: string, values: any) => {
  let value = values[name];
  if (name && name.indexOf(".") !== -1) {
    const obj = name.split(".");
    if (obj.length === 2) {
      value =
        typeof values[obj[0]] !== "undefined" ? values[obj[0]][obj[1]] : "";
    } else if (obj.length === 3) {
      value =
        typeof values[obj[0]] !== "undefined"
          ? values[obj[0]][obj[1]][obj[2]]
          : "";
    } else if (obj.length === 4) {
      value =
        typeof values[obj[0]] !== "undefined"
          ? values[obj[0]][obj[1]][obj[2]][obj[3]]
          : "";
    }
  }
  return value;
};

export default getValue;
