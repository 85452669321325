import { groupingInputType } from "../types";
import { apiClient } from "../utils";

export const createGrouping = async (groupingData: groupingInputType) => {
  const groupingInfo = await apiClient({
    url: "/grouping",
    method: "POST",
    data: groupingData,
  });
  return groupingInfo;
};

export const getGrouping = async () => {
  const groupingData = await apiClient({
    url: "/grouping",
    method: "GET",
  });
  return groupingData;
};

export const updateGrouping = async (
  updatedGroupingData: groupingInputType,
  groupingId: string
) => {
  const groupingInfo = await apiClient({
    url: `/grouping/${groupingId}`,
    method: "PATCH",
    data: updatedGroupingData,
  });
  return groupingInfo;
};
