import getValue from "./getValue";

type TextAreaProps = {
  keyId: number;
  label: string | undefined;
  name: string;
  values: any;
  handleChange: (event: any) => void;
  value: any;
  placeholder: any;
  maxLength?: number;
  isInputType?: boolean;
  inputTargetId?: number;
  isOptional?: boolean;
};
const TextAreaField = ({
  keyId,
  label,
  name,
  values,
  handleChange,
  value,
  placeholder,
  maxLength,
  isInputType,
  inputTargetId,
  isOptional,
  ...allProps
}: TextAreaProps) => {
  return (
    <div className="mb-4">
      {label ? (
        <label className="text-small-custom-3 text-light-black-color">
          {`${label} ${isOptional ? "(optional)" : ""}`}
        </label>
      ) : null}
      <textarea
        {...allProps}
        name={name}
        onChange={(event: any) => {
          handleChange(event);
        }}
        placeholder={placeholder}
        key={keyId}
        autoComplete="off"
        className={`appearance-none border-b font-open-sans font-normal text-base border-gray-600 block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 leading-tight focus:outline-none rounded-t-md focus:border-gray-500 h-20 resize-none ${
          !isInputType ? "w-6/12" : ""
        }`}
        maxLength={maxLength || 180}
        tabIndex={isInputType && inputTargetId ? inputTargetId : 0}
        value={getValue(name, values)}
      ></textarea>
    </div>
  );
};

export default TextAreaField;
