type LabelProps = {
  label: string;
  fontType: string;
};
const Label = ({ label, fontType }: LabelProps) => {
  let fontStyle = fontType === "bold" ? "font-bold capitalize" : "font-normal";
  return (
    <label
      className={`block tracking-wide font-open-sans text-light-black-color text-small-custom-1 
      ${fontStyle} mb-1`}
    >
      {label}
    </label>
  );
};

export default Label;
