const formValues = ({ values }: any) => {
  const valueObject: any = {};
  for (let [key, value] of Object.entries(values)) {
    if (key && key.indexOf(".") !== -1) {
      const obj: any = key.split(".");

      if (obj.length === 2) {
        valueObject[obj[0]][obj[1]] = value;
      } else if (obj.length === 3) {
        valueObject[obj[0]][obj[1]][obj[2]] = value;
      } else if (obj.length === 4) {
        valueObject[obj[0]][obj[1]][obj[2]][obj[3]] = value;
      }
    } else {
      valueObject[key] = value;
    }
  }
  return valueObject;
};

export default formValues;
