export const ActionIcon = () => (
  <svg
    width="25"
    height="35"
    fill="black"
    className="bi bi-three-dots-vertical"
    viewBox="0 0 10 15"
  >
    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
  </svg>
);

export const RightArrow = () => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 6 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 8C10.0006 7.60179 9.8422 7.21984 9.56006 6.93884C8.40568 5.72686 5.48434 3 3.08122 0.459998C2.49548 -0.125745 1.5458 -0.125745 0.960059 0.459998C0.374316 1.04574 0.374316 1.99542 0.960059 2.58116L6.37896 8L0.960059 13.4188C0.374316 14.0046 0.374316 14.9543 0.960059 15.54C1.5458 16.1257 2.49548 16.1257 3.08122 15.54L9.56006 9.06116C9.8422 8.78016 10.0006 8.3982 10.0001 8Z"
      fill="#141497"
    />
  </svg>
);

export const LeftArrow = () => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 1L2 5L6 9" stroke="black" strokeWidth="2" />
  </svg>
);

export const DownArrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99994 13C8.39815 13.0005 8.7801 12.8421 9.0611 12.56C10.2731 11.4056 12.9999 8.48428 15.5399 6.08116C16.1257 5.49542 16.1257 4.54574 15.5399 3.96C14.9542 3.37426 14.0045 3.37426 13.4188 3.96L7.99994 9.3789L2.5811 3.96C1.99536 3.37425 1.04568 3.37425 0.459941 3.96C-0.125801 4.54574 -0.125801 5.49541 0.459941 6.08116L6.93878 12.56C7.21978 12.8421 7.60174 13.0005 7.99994 13Z"
      fill="#141497"
    />
  </svg>
);

export const CheckMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4518 10.1618C5.21268 10.1879 4.96515 10.107 4.78059 9.91755L0.575658 5.60036C0.252258 5.26833 0.25164 4.73063 0.579346 4.39418C0.904783 4.06005 1.4279 4.05541 1.75417 4.39039L5.38823 8.12147L12.2423 1.08445C12.5666 0.751425 13.0973 0.748298 13.4228 1.08242C13.7505 1.41888 13.7473 1.95918 13.4208 2.29442L6.00154 9.91174C5.8495 10.0678 5.65212 10.1515 5.4518 10.1618Z"
      fill="#677274"
    />
  </svg>
);

export const SingleDash = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="2"
    viewBox="0 0 13 2"
    fill="none"
  >
    <path
      d="M0.520508 1.49756V0.532715H12.4795V1.49756H0.520508Z"
      fill="#C4C4C4"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.83867 7L13.6192 11.7805C14.1269 12.2883 14.1269 13.1115 13.6192 13.6192C13.1115 14.1269 12.2883 14.1269 11.7805 13.6192L7 8.83867L2.21947 13.6192C1.71173 14.1269 0.888534 14.1269 0.3808 13.6192C-0.126933 13.1115 -0.126933 12.2883 0.3808 11.7805L5.16133 7L0.3808 2.21947C-0.126933 1.71173 -0.126933 0.888534 0.3808 0.3808C0.888534 -0.126933 1.71173 -0.126933 2.21947 0.3808L7 5.16133L11.7805 0.3808C12.2883 -0.126933 13.1115 -0.126933 13.6192 0.3808C14.1269 0.888534 14.1269 1.71173 13.6192 2.21947L8.83867 7Z"
      fill="#A2A2A4"
    />
  </svg>
);

export const CloseX = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5401 13.4188L10.1212 7.99997L15.5401 2.58113C15.919 2.20222 16.0669 1.64995 15.9283 1.13235C15.7896 0.614752 15.3853 0.210462 14.8677 0.0717714C14.3501 -0.0669188 13.7978 0.0810619 13.4189 0.459971L8.00006 5.87887L2.58122 0.459971C1.99548 -0.125772 1.0458 -0.125772 0.460059 0.459971C-0.125684 1.04571 -0.125684 1.99539 0.460059 2.58113L5.87896 7.99997L0.460059 13.4188C-0.125684 14.0046 -0.125684 14.9542 0.460059 15.54C1.0458 16.1257 1.99548 16.1257 2.58122 15.54L8.00006 10.1211L13.4189 15.54C14.0046 16.1257 14.9543 16.1257 15.5401 15.54C16.1258 14.9542 16.1258 14.0046 15.5401 13.4188Z"
      fill="#141497"
    />
  </svg>
);
