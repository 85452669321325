import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastOptions = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
};
export const successAlert = (msg: any) => toast.success(msg, toastOptions);

export const errorAlert = (msg: string) => toast.error(msg, toastOptions);
