import {
  defaultCustomInputFields,
  defaultOfficialInputFields,
  defaultUniqueFields,
  officalUniqueIdentifiers,
  defaultUniqueFieldsBC,
} from "./create/constants";
import { dataTypeFields } from "../../types";
import { TEXT, ACCEPTABLE_VALUES } from "../../utils";

export const getOfficialUniqueIdentifiersByKeyGroup = (keyGroup: string) => {
  let officialUniqueFields: dataTypeFields[] = [];
  if (officalUniqueIdentifiers && officalUniqueIdentifiers[keyGroup]) {
    let fieldData: any = {};
    officalUniqueIdentifiers[keyGroup].forEach((fieldName: string) => {
      if (keyGroup === "A") {
        fieldData = defaultUniqueFields.find(
          (field: any) => field.name === fieldName
        );
      } else {
        fieldData = defaultUniqueFieldsBC.find(
          (field: any) => field.name === fieldName
        );
      }

      if (fieldData) {
        officialUniqueFields.push({
          ...fieldData,
          ...{ required: true, isUnique: false },
        });
      }
    });
  }
  return officialUniqueFields;
};

export const getUniqueFieldsBasedOnFileFormat = (
  formValuesObj: {
    [key: string]: any;
  },
  name: string
) => {
  let uniqueFields: dataTypeFields[] = [];
  if (formValuesObj.format === "Custom") {
    uniqueFields = JSON.parse(JSON.stringify(defaultUniqueFields));
    uniqueFields.push({ ...defaultCustomInputFields });
    formValuesObj.keyGroup = "A";
  }
  if (formValuesObj.format === "Official") {
    uniqueFields = getOfficialUniqueIdentifiersByKeyGroup(
      formValuesObj.keyGroup
    );
    if (name === "keyGroup") {
      uniqueFields = [
        ...uniqueFields,
        ...formValuesObj.fields.filter(
          (field: dataTypeFields) => !field?.nonEditable
        ),
      ];
    } else {
      uniqueFields.push({
        ...defaultOfficialInputFields,
        ...{ isScoreKey: true, isUnique: false },
      });
    }
  }

  return uniqueFields;
};

export const changeFormatRadioGroup = (
  name: string,
  formValuesObj: { [key: string]: any },
  updateFormError: (errStatus: any) => void
) => {
  if (name === "format" || name === "keyGroup") {
    let newFields: dataTypeFields[] = [];
    updateFormError({ fieldErr: false, columnErr: false });
    newFields = getUniqueFieldsBasedOnFileFormat(formValuesObj, name);
    formValuesObj.fields = newFields;
  }
};

export const validateUniqueAttributes = (
  isUniqueFieldPresent: dataTypeFields[]
) => {
  if (isUniqueFieldPresent && isUniqueFieldPresent.length > 0) {
    const uniqueField = isUniqueFieldPresent[0];

    if (uniqueField.isUnique) {
      if (uniqueField.inputType !== TEXT) {
        throw new Error(
          "Field marked as sub test type must have input type as Text"
        );
      } else if (
        !uniqueField.acceptableValues ||
        uniqueField.validationType !== ACCEPTABLE_VALUES
      ) {
        throw new Error(
          "Field marked as sub test type must contain AcceptableValues"
        );
      } else if (!uniqueField.required) {
        throw new Error(
          "Field marked as sub test type should be a required field"
        );
      }
    }
  }
};
