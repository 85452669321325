import { Select } from "antd";
import getValue from "./getValue";

type DropdownProps = {
  type: string;
  keyId: number;
  label: string;
  name: string;
  values: any;
  errors?: any;
  handleChange: (event: any) => void;
  options: { label: string; value: string }[];
};

const ValidationTypeDropdown = ({
  type,
  keyId,
  label,
  name,
  values,
  handleChange,
  options,
}: DropdownProps) => {
  const defaultValue = getValue(name, values) ? getValue(name, values) : "0";
  return (
    <div key={keyId} className="select-wrapper">
      <div className="mb-4">
        {label ? (
          <label className="block tracking-wide font-open-sans text-light-black-color text-small-custom-3 font-normal">
            {label}
          </label>
        ) : null}
        <div>
          <Select
            onChange={(value: string) => {
              const target = {
                name,
                value,
                type,
              };
              handleChange({ target });
            }}
            key={keyId}
            defaultValue={defaultValue}
            className={`select-dropdown border-b font-open-sans font-normal text-small-custom-3 border-gray-600 block bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 focus:outline-none rounded-t-md focus:border-gray-500 h-10 w-[420px]`}
          >
            <Select.Option
              value="0"
              className="pt-2.5 pb-0 px-4 text-light-black-color"
              disabled
            >
              {"Choose an option"}
            </Select.Option>
            {options &&
              options.map((option) => (
                <Select.Option
                  value={option.value}
                  key={option.value}
                  className="pt-2.5 pb-0 px-4 text-light-black-color"
                >
                  {option.label}
                </Select.Option>
              ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default ValidationTypeDropdown;
