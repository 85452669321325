import dayjs, { ConfigType } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const dateWithTime = (date?: ConfigType): string =>
  dayjs(date).format("MM/DD/YYYY hh:mm A");

export const dateWithTimeAndZone = (date?: ConfigType): string =>
  dayjs(date).format("MM/DD/YYYY hh:mm A z");
