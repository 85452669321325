import React from "react";

const drawerContext = React.createContext({
  DrawerStatus: false,
  createDrawer: false,
  ShowCreateDrawer: () => {},
  ShowEditDrawer: () => {},
  HideDrawer: () => {},
  GetGroupingData: () => {},
});

export default drawerContext;
