import Icon from "@ant-design/icons";
import { Table, Dropdown, Menu } from "antd";
import { MouseEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ListDataTypes } from "./ListDataTypes";
import { updateGrouping } from "../../../api";
import { Loader, Button } from "../../../components";
import { groupingType, statusType } from "../../../types";
import { dateWithTime } from "../../../utils";
import drawerContext from "../../../utils/drawerContext";
import {
  ActionIcon,
  DownArrow,
  RightArrow,
} from "../../../utils/icons/svgIcons";
import "./ListGrouping.css";

type ListGroupingProps = {
  loading: boolean;
  grouping: groupingType[];
  setRowData: (rowData: groupingType) => void;
};

const actionMenu = (
  status: string,
  record: groupingType,
  updateGroupingStatus: Function,
  editGrouping: Function
) => {
  const publishStatus = {
    status: "Published",
  };
  const unPublishStatus = {
    status: "UnPublished",
  };

  if (status === "Published") {
    return (
      <Menu>
        <Menu.Item
          key={1}
          onClick={() => updateGroupingStatus(unPublishStatus, record)}
        >
          Unpublish
        </Menu.Item>
      </Menu>
    );
  } else {
    return (
      <Menu>
        <Menu.Item key={1} onClick={() => editGrouping(record)}>
          Edit
        </Menu.Item>
        <Menu.Item
          key={2}
          onClick={() => updateGroupingStatus(publishStatus, record)}
        >
          Publish
        </Menu.Item>
      </Menu>
    );
  }
};

const ArrowDownIcon = (props: any) => (
  <Icon tabIndex={1} component={DownArrow} {...props} />
);
const ArrowRightIcon = (props: any) => (
  <Icon tabIndex={1} component={RightArrow} {...props} />
);

export const ListGrouping = ({
  grouping,
  loading,
  setRowData,
}: ListGroupingProps) => {
  const drawerCxt = useContext(drawerContext);
  const navigate = useNavigate();

  const updateGroupingStatus = async (
    updatedStatus: statusType,
    record: groupingType
  ) => {
    try {
      await updateGrouping(updatedStatus, record.id);
      drawerCxt.GetGroupingData();
    } catch (err) {
      console.log(err);
    }
  };

  const editGrouping = (record: groupingType) => {
    drawerCxt.ShowEditDrawer();
    setRowData(record);
  };

  const columns = [
    {
      title: "Grouping",
      dataIndex: "name",
      key: "name",
      width: "76%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "11%",
      render: (status: string) => {
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            id={
              status === "Published"
                ? "grouping-badge-published"
                : "grouping-badge-unpublished"
            }
          >
            {status === "UnPublished" ? "Unpublished" : "Published"}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: "8%",
      dataIndex: "status",
      render: (status: string, record: groupingType) => (
        <div className="ml-3" onClick={(e) => e.stopPropagation()}>
          <Dropdown
            trigger={["click"]}
            overlay={actionMenu(
              status,
              record,
              updateGroupingStatus,
              editGrouping
            )}
            placement="bottom"
            arrow
            overlayClassName="custom-action-dropdown"
          >
            <Icon tabIndex={1} component={ActionIcon} />
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      {loading && <Loader size="medium" />}
      {!loading && grouping && (
        <Table
          data-test-id="grouping-list-table"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={grouping}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <div className="expanded-cell">
                <div className="flex text-sm">
                  <p className="font-bold pr-2">Date Created </p>
                  <p>
                    {dateWithTime(record.CT)} ({record.CB})
                  </p>
                </div>
                <div className="flex text-sm">
                  <p className="font-bold pr-2">Date Published</p>
                  <p>
                    {dateWithTime(record.PT)} ({record.PB})
                  </p>
                </div>

                <div className="flex justify-between my-2">
                  <h1 className="pt-4 font-open-sans font-bold text-sm">
                    Data Types in Grouping
                  </h1>
                  <Button
                    onClick={() => navigate(`/datatype/create/${record.id}`)}
                    tabIndex={1}
                    className=" bg-gradient-to-b from-btn-blue-color to-dark-blue-color text-white text-base font-open-sans font-normal py-2 px-4 rounded hover:from-dark-blue-color hover:to-dark-blue-color"
                  >
                    Create New Data Type
                  </Button>
                </div>
                <ListDataTypes groupingId={record.id} />
              </div>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <ArrowDownIcon
                  className="mb-1"
                  onKeyUp={(e: any) => {
                    if (e.code === "Space") {
                      onExpand(record, e);
                    }
                  }}
                  onClick={(e: MouseEvent<HTMLButtonElement>) =>
                    onExpand(record, e)
                  }
                />
              ) : (
                <ArrowRightIcon
                  className="mb-1"
                  onKeyUp={(e: any) => {
                    if (e.code === "Space") {
                      onExpand(record, e);
                    }
                  }}
                  onClick={(e: MouseEvent<HTMLButtonElement>) =>
                    onExpand(record, e)
                  }
                />
              ),
            expandRowByClick: true,
          }}
        />
      )}
    </>
  );
};
