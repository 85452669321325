import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from "antd";
import { useContext } from "react";
import drawerContext from "../../utils/drawerContext";
import "./Drawer.css";

interface DrawerProps extends AntdDrawerProps {
  children: React.ReactNode;
}
const Drawer = ({ children, ...args }: DrawerProps) => {
  const drawerCxt = useContext(drawerContext);
  return (
    <>
      <AntdDrawer
        width={532}
        onClose={drawerCxt.HideDrawer}
        visible={drawerCxt.DrawerStatus}
        {...args}
      >
        {children}
      </AntdDrawer>
    </>
  );
};

export default Drawer;
