import "./Loader.css";
import React from "react";

type Size = "small" | "medium" | "large";

export interface LoaderProps {
  size: Size;
}

const Loader = (props: LoaderProps): JSX.Element => {
  (Loader as React.FC).displayName = "Loader";
  const { size } = props;

  return (
    <div className="overlay">
      <div className={`loader loader-${size}`}>
        <div className="loader-bar"></div>
        <div className="loader-bar"></div>
        <div className="loader-bar"></div>
        <div className="loader-bar"></div>
      </div>
    </div>
  );
};

export default Loader;
